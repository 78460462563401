export const ExperimentsConsts = {
  UoUTemplateModeModal: 'specs.bookings.UoUTemplateModeModal',
  DynamicPricingUoU: 'specs.bookings.dynamicPricingUoU',
  DynamicPricingCustomUoU: 'specs.bookings.dynamicPricingCustomUoU',
  HidePayLaterIfEqualToTotalPrice:
    'specs.bookings.HidePayLaterIfEqualToTotalPrice',
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  FixDurationOver24Hours: 'specs.bookings.FixDurationOver24Hours',
  FixFormUoUCheckboxLink: 'specs.bookings.FixFormUoUCheckboxLink',
};
